
import { Component, Vue, Watch } from 'vue-property-decorator'
import axios from '@/utils/axios'
import { showError } from '@/helpers/notifications'
import { userStore } from '@/store/userStore'
import { ProductGroup } from '@/enums/productGroup'

@Component
export default class PricesPage extends Vue {
  products: {
    id: string
    title: string
    enabled: boolean
    price: number
    oldPrice: number
    priceError: boolean
    group: ProductGroup
  }[] = []
  pending = false
  searchString = ''
  selectedGroup: ProductGroup | null = null
  groups: { value: ProductGroup | null; text: string }[] = [
    { value: null, text: '-' },
    { value: ProductGroup.hotDog, text: 'Хот-доги' },
    { value: ProductGroup.burger, text: 'Бургеры' },
    { value: ProductGroup.snack, text: 'Закуски' },
    { value: ProductGroup.drink, text: 'Напитки' },
    { value: ProductGroup.sauce, text: 'Соусы' },
    { value: ProductGroup.topping, text: 'Топпинги' },
  ]

  get shop() {
    return userStore.selectedShop
  }

  get filteredProducts() {
    return this.products.filter(product => {
      let finded = product.title
        .toLowerCase()
        .includes(this.searchString.toLowerCase())
      if (this.selectedGroup) {
        finded &&= product.group === this.selectedGroup
      }
      return finded
    })
  }

  @Watch('shop')
  onShopChanged() {
    this.fetchPrice()
  }

  async mounted() {
    this.fetchPrice()
  }

  async fetchPrice() {
    if (!this.shop) {
      return
    }

    this.pending = true

    try {
      const response = await axios.get(
        `products/price?shop=${userStore.selectedShop}`,
      )
      this.products = response.data.map((p: any) => ({
        ...p,
        oldPrice: p.price,
        priceError: null,
      }))
    } catch (error) {
      console.error(error)
      showError('При получении товаров произошла ошибка')
    }
    this.pending = false
  }

  async onProductEnabledChange(value: boolean, id: string) {
    this.pending = true

    try {
      await axios.patch(`products/enabled`, {
        product: id,
        shop: this.shop,
        value,
      })
    } catch (error) {
      console.error(error)
      showError('При изменении товара произошла ошибка')
      this.products.find(p => p.id === id)!.enabled = !value
    }
    this.pending = false
  }

  async onPriceChanged(id: string) {
    const product = this.products.find(p => p.id === id)

    if (!product || product.price === product.oldPrice) {
      return
    }

    if (!Number.isInteger(product.price)) {
      showError('Цена должна быть целым числом')
      product.priceError = true
      return
    }

    if (product.price < 1) {
      showError('Цена должна быть больше 0')
      product.priceError = true
      return
    }

    this.pending = true

    try {
      await axios.patch(`products/price`, {
        product: id,
        shop: this.shop,
        price: product.price,
      })
      product.oldPrice = product.price
    } catch (error) {
      console.error(error)
      showError('При изменении товара произошла ошибка')
      product.priceError = true
    }
    this.pending = false
  }
}
